import * as React from "react"
import { Link } from "gatsby"

// components
import Header from "../components/header"
import Banner from "../components/banner"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { usePage } from "../hooks/home-page"

import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap'


// styles


// data

// markup

const NotFoundPage = () => {
  const home = usePage();
  return(
    <div>
      {home.map(node => {
        const videoBackgroundFluidImageStack = [
          node.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()
        return(
          <main>
            <SEO title="Page Not Found" />
            <Header />
            <Banner 
              title="Page Not Found" 
          
              backgroundImage={node.acfPageHeader.bannerBackgroundImage}
              >
        
            </Banner>  
      
            <Container>
              
                &nbsp;
            </Container>
            <Footer />
          </main>
        );
      })}
    </div> 
  );
}

export default NotFoundPage

